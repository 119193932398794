import React from 'react'
import { config } from '../NavigationButtons/config'
import {
  AccountBenefits,
  Activity,
  BillPayment,
  CheckCapture,
  Critical,
  Desktop,
  DirectDeposit,
  Flag,
  Help,
  Home,
  Merchant,
  Mgm,
  PackageSize,
  Phone,
  PhoneAlt,
  PPCashCard,
  Qrc,
  Rocket,
  ShippingPack,
  SwitchCard,
  Verified,
  Website,
  World,
} from '../../../icons'

const iconProps = {
  width: '24',
  height: '24',
  primaryColor: '#142C8E',
  secondaryColor: '#142C8E',
  thirdColor: '#142C8E',
  fourthColor: '#142C8E',
  fifthColor: '#142C8E',
  sixthColor: '#142C8E',
  seventhColor: '#142C8E',
  eighthColor: '#142C8E',
}

export const navbarRoutes = config
