import React, { ReactNode, useCallback, useState } from 'react'
import { MobileMenuItem } from '..'
import { ArrowLeft, ChevronRight } from '../../../../icons'
import '../styles.css'

interface MobileMenuChildren {
  title: string
  url: string
}

interface MobileMultiLevelProps {
  title: string
  url: string
  icon: ReactNode | ReactNode[]
  children: MobileMenuChildren[]
}

export const MobileMultiLevel: React.FC<MobileMultiLevelProps> = ({
  title,
  url,
  icon,
  children,
}) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [selection, setSelection] = useState<any>('main')

  const handleClick = useCallback((id: any) => {
    setActiveMenu(id)
    setSelection(id)
  }, [])

  const handleGoBack = useCallback(() => {
    setActiveMenu('main')
    setSelection('main')
  }, [])

  return (
    <>
      {activeMenu === 'main' && selection === 'main' && (
        <div className="mobile-menu-link-wrapper">
          <button
            onClick={() => handleClick(title)}
            className="mobile-menu-button"
          >
            <span className="mobile-menu-title">{title}</span>
            <ChevronRight fill="#515354" />
          </button>
        </div>
      )}

      {activeMenu === title && (
        <>
          <div className="mobile-menu-goback-container">
            <button
              className="mobile-menu-goback-button"
              onClick={handleGoBack}
            >
              <ArrowLeft />
            </button>
            <h2 className="mobile-menu-active-title">{title}</h2>
          </div>
          <div className="mobile-menu-dropdown-links-top-shadow" />
          {children?.map((child) =>
            icon ? (
              <div
                key={child.title}
                className="mobile-menu-item-icon-container"
              >
                {icon && icon}
                <MobileMenuItem item={child} key={child.title} />
              </div>
            ) : (
              <MobileMenuItem item={child} key={child.title} />
            ),
          )}
        </>
      )}
    </>
  )
}
