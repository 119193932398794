import axios from 'axios'
import { getCookieValue } from './cookie'

const csrfCookieName = 'XSRF-TOKEN'
const csrfHeaderName = 'X-XSRF-TOKEN'

function getRequest(url, params) {
  return axios({
    method: 'get',
    url,
    params,
    responseType: 'json',
    contentType: 'application/json',
  })
}

function postRequest(url, data = {}, headers = {}, disableCsrf = false) {
  if (!disableCsrf) {
    headers[csrfHeaderName] = getCookieValue(csrfCookieName)
  }

  return axios({
    method: 'post',
    url,
    data,
    headers,
    responseType: 'json',
    contentType: 'application/json',
  })
}

function deleteRequest(url, params) {
  return axios({
    method: 'delete',
    url,
    params,
    responseType: 'json',
    contentType: 'application/json',
  })
}

function putRequest(url, data) {
  return axios({
    method: 'put',
    url,
    data,
    responseType: 'json',
    contentType: 'application/json',
  })
}

export { getRequest, postRequest, deleteRequest, putRequest }
