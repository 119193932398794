import React from 'react'
import { MobileMultiLevel } from './MobileMultiLevel/MobileMultiLevel'
import { MobileSingleLevel } from './MobileSingleLevel/MobileSingleLevel'
import { hasChildren } from './utils'

interface MobileMenuItemProps {
  item: any
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ item }) => {
  const Component = hasChildren(item) ? MobileMultiLevel : MobileSingleLevel
  return <Component {...item} />
}
