import { Link } from 'gatsby'
import React, { ReactNode } from 'react'
import '../styles.css'

interface MobileSingleLevelProps {
  url: string
  title: string
  icon: ReactNode | ReactNode[]
}

export const MobileSingleLevel: React.FC<MobileSingleLevelProps> = ({
  url,
  title,
  icon,
}) => {
  const linkContainerClassName = [
    icon
      ? 'mobile-menu-item-icon-container mobile-menu-link-container'
      : 'mobile-menu-link-container',
  ].join(' ')

  const titleClassName = [
    icon ? 'mobile-menu-title mobile-menu-icon-title' : 'mobile-menu-title',
  ].join(' ')

  return (
    <div className="mobile-menu-link-wrapper">
      <a href={url} className={linkContainerClassName}>
        {icon && icon}
        <span className={titleClassName}>{title}</span>
      </a>
    </div>
  )
}
