/**
  Util to manage browser cookies.
 */

function getCookieValue(name) {
  const nameString = name + '='
  const value = document.cookie.split(';').filter((item) => {
    return item.includes(nameString)
  })

  if (value.length) {
    const cookieVal = value[0].substring(nameString.length, value[0].length)
    return cookieVal
  }
  return ''
}

export { getCookieValue }
