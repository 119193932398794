import { Button } from '@paypalcorp/pp-react'
import { Link } from 'gatsby'
import React, { useCallback, useState } from 'react'
import { ArrowLeft, Avatar, ChevronRight } from '../../../icons'
import { MobileMenuItem } from '../MobileMenuItem/MobileMenuItem'
import { hasChildren } from '../MobileMenuItem/utils'
import { navbarRoutes } from '../utils/navbarRoutes'
import './mobile-menu.css'

interface MobileMenuProps {
  loginURL: any
  logoutURL: any
  user?: any
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  loginURL,
  logoutURL,
  user = {},
}) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [selection, setSelection] = useState<any>('main')

  const profileRoutes = [
    {
      title: 'Dashboard',
      url: '/dashboard/',
    },
    {
      title: 'My Account',
      url: '/developer/accountStatus/',
    },
    {
      title: 'Log Out',
      url: logoutURL,
    },
  ]

  const handleClick = useCallback((id: any) => {
    setActiveMenu(id)
    setSelection(id)
  }, [])

  const handleGoBack = useCallback(() => {
    setActiveMenu('main')
    setSelection('main')
  }, [])

  return (
    <div className="mobile-menu-wrapper">
      <div className="mobile-menu-container">
        <div className="mobile-menu-subcontainer">
          <div className="mobile-menu-items-wrapper">
            <div className="mobile-menu-items-container">
              {user.loggedIn && (
                <>
                  {activeMenu === 'main' && selection === 'main' && (
                    <div className="mobile-menu-link-wrapper">
                      <button
                        onClick={() => handleClick(user.firstName)}
                        className="mobile-menu-button"
                      >
                        <div className="mobile-menu-user-container">
                          <span
                            className="mobile-menu-title"
                            style={{ marginRight: '1rem' }}
                          >
                            {user.firstName}
                          </span>
                          <Avatar />
                        </div>
                        <ChevronRight fill="#515354" />
                      </button>
                    </div>
                  )}
                  {activeMenu === user.firstName && (
                    <>
                      <div className="mobile-menu-goback-container">
                        <button
                          className="mobile-menu-goback-button"
                          onClick={handleGoBack}
                        >
                          <ArrowLeft />
                        </button>
                        <h2 className="mobile-menu-active-title">
                          {user.firstName}
                        </h2>
                      </div>
                      <div className="mobile-menu-dropdown-links-top-shadow" />
                      {profileRoutes?.map((child) => (
                        <MobileMenuItem item={child} key={child.title} />
                      ))}
                    </>
                  )}
                </>
              )}
              {navbarRoutes.map((child) =>
                hasChildren(child) ? (
                  <>
                    {activeMenu === 'main' && selection === 'main' && (
                      <>
                        <div className="mobile-menu-link-wrapper">
                          <button
                            onClick={() => handleClick(child.title)}
                            className="mobile-menu-button"
                          >
                            <span className="mobile-menu-title">
                              {child.title}
                            </span>
                            <ChevronRight fill="#515354" />
                          </button>
                        </div>
                      </>
                    )}

                    {activeMenu === child.title && (
                      <>
                        <div className="mobile-menu-goback-container">
                          <button
                            className="mobile-menu-goback-button"
                            onClick={handleGoBack}
                          >
                            <ArrowLeft />
                          </button>
                          <h2 className="mobile-menu-active-title">
                            {child.title}
                          </h2>
                        </div>
                        <div className="mobile-menu-dropdown-links-top-shadow" />
                        {child.children?.map((child) => (
                          <MobileMenuItem item={child} key={child.title} />
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  activeMenu === 'main' &&
                  selection === 'main' && (
                    <div className="mobile-menu-link-wrapper">
                      <a
                        href={
                          child.title !== 'Support'
                            ? child.url
                            : '/docs/support'
                        }
                        className="mobile-menu-link-container"
                      >
                        <span className="mobile-menu-title">{child.title}</span>
                      </a>
                    </div>
                  )
                ),
              )}
              {activeMenu === 'main' &&
                (!user.loggedIn ? (
                  <div className="mobile-menu-login-btn__container">
                    <a
                      data-js="auth-btn"
                      href={loginURL}
                      data-dxa="login,nav-click,nav-login"
                    >
                      <Button theme="v2" secondary>
                        Login
                      </Button>
                    </a>
                  </div>
                ) : null)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
