import { SUPPORT_URL } from '../../../integration-builder/utils/constants'

export const config = [
  {
    id: 1,
    title: 'Docs',
    children: [
      {
        title: 'Online',
        url: '/docs/online/',
      },
      {
        title: 'In-Person',
        url: '/docs/in-person/',
      },
      {
        title: 'Multiparty',
        url: '/docs/multiparty/',
      },
      {
        title: '3rd-Party',
        url: '/docs/3rd-party/',
      },
      {
        title: 'Payouts',
        url: '/docs/payouts/',
      },
      {
        title: 'Disputes',
        url: '/docs/disputes/',
      },
      {
        title: 'Reports',
        url: '/docs/reports/',
      },
      {
        title: 'Identity',
        url: '/docs/log-in-with-paypal/',
      },
      {
        title: 'Tracking',
        url: '/docs/tracking/',
      },
      {
        title: 'Docs Archive',
        url: '/docs/archive',
      },
    ],
  },
  {
    id: 2,
    title: 'APIs & SDKs',
    children: [
      {
        title: 'REST APIs',
        url: '/api/rest/',
      },
      {
        title: 'JavaScript SDK',
        url: '/sdk/js/',
      },
      {
        title: 'Mobile SDKs',
        url: '/sdk/mobile/',
      },
      {
        title: 'NVP/SOAP APIs',
        url: '/api/nvp-soap/',
      },
      {
        title: 'Donate SDK',
        url: '/sdk/donate/',
      },
      {
        title: 'Braintree GraphQL API',
        url: '/braintree/graphql/',
      },
    ],
  },
  {
    id: 3,
    title: 'Tools',
    children: [
      {
        title: 'Sandbox Testing Guide',
        url: '/tools/sandbox/',
      },
      {
        title: 'API Executor',
        url: 'https://www.paypal.com/apex/home',
      },
      {
        title: 'Demo Portal',
        url: 'https://demo.paypal.com/us/demo/home',
      },
      {
        title: 'Negative Testing',
        url: '/tools/sandbox/negative-testing/',
      },
      {
        title: 'Codespaces',
        url: '/api/rest/sandbox/codespaces/',
        isNew: true,
      },
      {
        title: 'VS Code Extension',
        url: '/tools/vscode',
        isNew: true,
      },
      {
        title: 'Credit Card Generator',
        url: '/api/rest/sandbox/card-testing/',
      },
      {
        title: 'Webhooks',
        url: '/api/rest/webhooks/',
      },
      {
        title: 'API Status',
        url: 'https://www.paypal-status.com/api/production',
      },
      {
        title: 'Secure File Transfer',
        url: '/tools/dropzone',
      },
    ],
  },
  {
    id: 4,
    title: 'Community',
    children: [
      {
        title: 'Community',
        url: 'https://developer.paypal.com/community/home/',
      },
      {
        title: 'Blog',
        url: 'https://developer.paypal.com/community/blog/',
      },
      {
        title: 'Events',
        url: 'https://developer.paypal.com/community/events/',
      },
      {
        title: 'Champions',
        url: 'https://developer.paypal.com/community/champions/',
      },
      {
        title: 'Videos',
        url: 'https://developer.paypal.com/video/home/',
      },
      {
        title: 'Developer Program',
        url: 'https://developer.paypal.com/developer-program/',
      },
    ],
  },
  {
    id: 5,
    title: 'Support',
    url: SUPPORT_URL,
  },
  {
    id: 6,
    title: 'PayPal.com',
    url: 'https://www.paypal.com',
  },
]
export const navLinks = [
  {
    id: 1,
    label: 'Docs',
    items: [
      {
        label: 'Online',
        url: '/docs/online/',
      },
      {
        label: 'In-Person',
        url: '/docs/in-person/',
      },
      {
        label: 'Multiparty',
        url: '/docs/multiparty/',
      },
      {
        label: '3rd-Party',
        url: '/docs/3rd-party/',
      },
      {
        label: 'Payouts',
        url: '/docs/payouts/',
      },
      {
        label: 'Disputes',
        url: '/docs/disputes/',
      },
      {
        label: 'Reports',
        url: '/docs/reports/',
      },
      {
        label: 'Identity',
        url: '/docs/log-in-with-paypal/',
      },
      {
        label: 'Tracking',
        url: '/docs/tracking/',
      },
      {
        label: 'Docs Archive',
        url: '/docs/archive',
      },
    ],
  },
  {
    id: 2,
    label: 'APIs & SDKs',
    items: [
      {
        label: 'REST APIs',
        url: '/api/rest/',
      },
      {
        label: 'JavaScript SDK',
        url: '/sdk/js/',
      },
      {
        title: 'Mobile SDKs',
        url: '/sdk/mobile/',
      },
      {
        label: 'NVP/SOAP APIs',
        url: '/api/nvp-soap/',
      },
      {
        label: 'Donate SDK',
        url: '/sdk/donate/',
      },
      {
        label: 'Braintree GraphQL API',
        url: '/braintree/graphql/',
      },
    ],
  },
  {
    id: 3,
    label: 'Tools',
    items: [
      {
        label: 'Sandbox Testing Guide',
        url: '/tools/sandbox/',
      },
      {
        label: 'API Executor',
        url: 'https://www.paypal.com/apex/home',
      },
      {
        label: 'Demo Portal',
        url: 'https://demo.paypal.com/us/demo/home',
      },
      {
        label: 'Negative Testing',
        url: '/tools/sandbox/negative-testing/',
      },
      {
        label: 'Codespaces',
        url: '/api/rest/sandbox/codespaces/',
        isNew: true,
      },
      {
        label: 'VS Code Extension',
        url: '/tools/vscode',
        isNew: true,
      },
      {
        label: 'Credit Card Generator',
        url: '/api/rest/sandbox/card-testing/',
      },
      {
        label: 'Webhooks',
        url: '/api/rest/webhooks/',
      },
      {
        label: 'API Status',
        url: 'https://www.paypal-status.com/api/production',
      },
      {
        label: 'Secure File Transfer',
        url: '/tools/dropzone',
      },
    ],
  },
  {
    id: 4,
    label: 'Community',
    items: [
      {
        label: 'Community',
        url: 'https://developer.paypal.com/community/home/',
      },
      {
        label: 'Blog',
        url: 'https://developer.paypal.com/community/blog/',
      },
      {
        label: 'Events',
        url: 'https://developer.paypal.com/community/events/',
      },
      {
        label: 'Champions',
        url: 'https://developer.paypal.com/community/champions/',
      },
      {
        label: 'Videos',
        url: 'https://developer.paypal.com/video/home/',
      },
      {
        title: 'Developer Program',
        url: 'https://developer.paypal.com/developer-program/',
      },
    ],
  },
  {
    id: 5,
    label: 'Support',
    url: SUPPORT_URL,
  },
  {
    id: 6,
    label: 'PayPal.com',
    url: 'https://www.paypal.com',
  },
]
