export const PERSIST_KEY: string = 'integration_builder'
export const SUPPORT_URL: string = 'https://developer.paypal.com/support/'

export const PAYMENT_METHOD_OPTIONS = [
  {
    id: '1',
    title: 'PayPal Payment Buttons',
    description:
      'Let PayPal intelligently present relevant payment methods to your buyers.',
    value: 'paypal-payment',
  },
  {
    id: '2',
    title: 'Standalone Payment Buttons',
    description: 'Individually select the payment methods you want to accept.',
    value: 'standalone-buttons',
  },
]

export const PAYMENT_FREQUENCY_OPTIONS = [
  {
    id: '1',
    title: 'One-time',
    description: 'Process one-time payments using the JavaScript SDK.',
    value: 'one-time',
  },
  {
    id: '2',
    title: 'Recurring',
    description:
      'Process payments for fixed amounts at regular intervals using the Catalog Product API, Subscriptions API, and JavaScript SDK.',
    value: 'recurring',
  },
]

export const LAYOUT_OPTIONS = [
  {
    value: 'vertical',
    primaryText: 'Vertical',
  },
  {
    value: 'horizontal',
    primaryText: 'Horizontal',
  },
]

export const SHAPE_OPTIONS = [
  {
    value: 'rect',
    primaryText: 'Rectangle',
  },
  {
    value: 'pill',
    primaryText: 'Pill',
  },
]
